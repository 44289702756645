import Button from "@mui/material/Button";

import React, { useState, useEffect, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, TextField } from "@mui/material";
import logo from "../../images/logo.png";
import dayjs from "dayjs";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import "./Style.css";
import "../../../src/App.css";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../Global";
import { FilterContext } from "./../../App";
import { event } from "jquery";

const GSTBill = (props) => {
  const { currentType, currentCompany } = useContext(FilterContext);

  const [analytics, setAnalytics] = useState({ _1: 0, _2: 0 });
  const [bid, setbid] = useState("");
  // const [formData, setFormData] = useState({
  //     advance: null,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  // });

  // ###################----SATISH KUMAR CHANGES----#####################----START----#######################
  const initialValue = {
    name: "",
    address: "",
    mobileNo: "",
    date: dayjs(Date.now()).format("YYYY-MM-DD"),
    gstNumber: "",
    advance: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [partyList, setPartyList] = useState([]);
  const [selectUnit, setSelectUnit] = useState("Sq.ft.");
  const [newUserOption, setNewUserOption] = useState(false);
  const [userSelect, setUserSelect] = useState();
  // ###################----SATISH KUMAR CHANGES----#####################----END----#######################

  const [items, setItems] = useState([]);
  const [products, setProducts] = useState(null);

  const [priceMode, setpriceMode] = useState(1);
  const [priceModeName, setpriceModeName] = useState("");

  const [billId, setBillId] = useState(null);
  const [currentProduct, setCurrentProduct] = useState("");
  const [currentUnit, setCurrentUnit] = useState("Sq.ft.");
  const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(1);
  const [currentHeight, setCurrentHeight] = useState(1);
  const [currentSqft, setCurrentSqft] = useState(1);
  const [currentQuantity, setCurrentQuantity] = useState(1);

  // #################----SATISH KUMAR CHAGES----######################----START----###########################
  const [userSelectProductId, setUserSelectProductId] = useState(0);
  // #################----SATISH KUMAR CHAGES----######################----END----###########################

  const [currentPrice, setCurrentPrice] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [companyFlag, setCompanyFlag] = useState(0);

  const [printComponentRef, setPrintComponentRef] = useState(null);

  const [saveFlag, setSaveFlag] = useState(false);

  const Unit = [{ label: "Sq.ft." }, { label: "Inch" }, { label: "MM" }];

  const PaymentMode = [
    { label: "Cash", name: "cash", value: "1" },
    { label: "Online", name: "Online", value: "2" },
  ];

  // delete
  const deleteItem = (id) => {
    console.log("clicked" + id);

    setItems((oldItems) => {
      return oldItems.filter((arrElem, index) => {
        if (index == id) {
          setNetAmount(Number(netAmount) - Number(arrElem.price));
        }
        return index !== id;
      });
    });

    // update analytics
    let _analytics = analytics;
    if (currentCompany == 1) {
      _analytics._1 = _analytics._1 - 1;
    } else if (currentCompany == 2) {
      _analytics._1 = _analytics._2 - 1;
    }
    setAnalytics(_analytics);
  };

  const listOfItems = () => {
    // update grand total
    setNetAmount(Number(netAmount) + Number(currentPrice));

    let multiplier =
      currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 12 : 305;

    setItems((oldData) => {
      return [
        ...oldData,
        {
          product: currentProduct,
          unit: currentUnit,
          unitPrice: currentUnitPrice,
          quantity: currentQuantity,
          width: currentWidth,
          height: currentHeight,
          sqft: currentSqft,
          price: currentPrice,
        },
      ];
    });

    // update analytics
    let _analytics = analytics;
    if (currentCompany == 1) {
      _analytics._1 = _analytics._1 + 1;
    } else if (currentCompany == 2) {
      _analytics._1 = _analytics._2 + 1;
    }
    setAnalytics(_analytics);

    if (currentCompany == 1 && companyFlag == 0) {
      setCompanyFlag(1);
    } else if (currentCompany == 2 && companyFlag == 0) {
      setCompanyFlag(2);
    } else {
      setCompanyFlag(3);
    }
  };

  const updateCurrentPrice = (type, value) => {
    let divisor =
      currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;

    if (type === "unit") {
      setCurrentUnit(value);
      let divisor = value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
      setCurrentPrice(
        (
          (currentWidth * currentHeight * currentUnitPrice * currentQuantity) /
          divisor
        ).toFixed(2)
      );
    } else if (type === "unitPrice") {
      setCurrentUnitPrice(value);
      setCurrentPrice(
        (
          (currentWidth * currentHeight * value * currentQuantity) /
          divisor
        ).toFixed(2)
      );
    } else if (type === "quantity") {
      setCurrentQuantity(value);
      setCurrentPrice(
        (
          (currentWidth * currentHeight * currentUnitPrice * value) /
          divisor
        ).toFixed(2)
      );
    } else if (type === "product") {
      setCurrentUnitPrice(value);
      setCurrentPrice(
        (
          (currentWidth * currentHeight * value * currentQuantity) /
          divisor
        ).toFixed(2)
      );
    } else if (type === "width") {
      setCurrentWidth(value);

      setCurrentPrice(
        (
          (value * currentHeight * currentUnitPrice * currentQuantity) /
          divisor
        ).toFixed(2)
      );
    } else if (type === "height") {
      setCurrentHeight(value);
      setCurrentPrice(
        (
          (currentWidth * value * currentUnitPrice * currentQuantity) /
          divisor
        ).toFixed(2)
      );
    }
    updateSqft(type, value);
  };

  const updateSqft = (type, value) => {
    let multiplier =
      currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;
    if (type === "width") {
      setCurrentSqft(
        (((value * currentHeight) / multiplier) * currentQuantity).toFixed(2)
      );
    } else if (type === "height") {
      setCurrentSqft(
        (((currentWidth * value) / multiplier) * currentQuantity).toFixed(2)
      );
    } else if (type === "unit") {
      let multiplier = value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
      setCurrentSqft(
        (
          ((currentWidth * currentHeight) / multiplier) *
          currentQuantity
        ).toFixed(2)
      );
    } else if (type === "quantity") {
      setCurrentSqft(
        (((currentWidth * currentHeight) / multiplier) * value).toFixed(2)
      );
    }
  };

  // console.log({ userSelectProductId });

  // insert bill list
  const insertBillList = (billId) => {
    let url = API_URL;

    // 1.  insert into deliveryMemoList
    items.map((item, index) => {
      const query = `INSERT INTO billList(billType, partyId, billId, particular, quantity, rate, width, height, sqft, amount) VALUES(
          1,
          ${userSelectProductId},
          ${billId},
          '${item.product}', 
          ${item.quantity}, 
          ${(item.price / item.sqft).toFixed(2)},
          ${item.width},
          ${item.height}, 
          ${item.sqft},
          ${item.price}
        )`;
      console.log(query);
      let data = { crossDomain: true, crossOrigin: true, query: query };
      axios
        .post(url, data)
        .then((res) => {
          console.log("insertBillList successfull, index: ", index);
        })
        .catch((err) => {
          console.log("failed to insertBillList, error: ", err);
        });
    });
  };

  // fetching data
  const fetchProductList = () => {
    let url = API_URL;
    // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    const query = `select id, name as label, price from products where status = 1 and customerType = ${currentType} and company = ${currentCompany} order by name asc`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        let data = [];

        setProducts(res.data);
      })
      .catch((err) => {
        console.log("products fetch error: ", err);
      });
  };

  // save Bill
  const saveBill = () => {
    const newCDate =
      formData.date == null
        ? dayjs(Date.now()).format("YYYY-MM-DD")
        : dayjs(formData.date).format("YYYY-MM-DD");
    let advance =
      formData.advance == null
        ? netAmount * 0.18 + netAmount
        : formData.advance;

    let query = `INSERT INTO gstBill (billNumber, gstNumber, companyGstNumber, companyType, partyName, mobileNo, address, date, gst, total, paid, balance, paymentMode) values(
        "${String(dayjs(Date.now()).format("MMMYY/DD")).toUpperCase()}",
        "${formData.gstNumber}",
        "27EMNPP2479H1ZD",
        ${companyFlag},
        "${formData.name}",
       "${formData.mobileNo}",
       "${formData.address}",
       "${newCDate}",
       ${netAmount * 0.18},
       ${netAmount * 0.18 + netAmount},
       ${advance},
       ${netAmount * 0.18 + netAmount - advance},
       ${priceMode}
        )`;
    let query2 = `INSERT INTO party (name, address, mobile) values(
            "${formData.name}",
            "${formData.address}",
            "${formData.mobileNo}"
            )`;

    let data = { crossDomain: true, crossOrigin: true, query: query };
    let data2 = { crossDomain: true, crossOrigin: true, query: query2 };
    axios
      .post(API_URL, data)
      .then((res1) => {
        axios.post(API_URL, data2).then((res) => {
          console.log("party inserted successfully");
          toast.success("Bill. generated successfully");
          //console.log(res.data.insertId);
          //console.log("res1", res1.data.insertId);
          setBillId(res.data.insertId);
          insertBillList(res1.data.insertId);
        });
      })
      .catch((err) => {
        toast.error("Failed to Generate Bill ");
      });
  };

  useEffect(() => {
    fetchProductList();
  }, [currentType, currentCompany]);

  // updateCompany();

  // <h6>{["Imprint24", "SignMart"][currentCompany - 1]}</h6>
  // {["Imprint24", "SignMart"][currentCompany - 1]}

  // ###################---SATISH KUMAR CHANGES----##################----START----############################

  const fetchAllPartyList = async () => {
    let url = API_URL;
    const query = `SELECT name, address, mobile as mobileNo FROM party`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    const response = await axios.post(url, data);
    if (response.status === 200) {
      return setPartyList(response.data);
    } else {
      return [];
    }
  };

  useEffect(() => {
    fetchAllPartyList();
  }, []);

  useEffect(() => {
    if (userSelect === null || userSelect === undefined) {
      setFormData(initialValue);
    } else {
      setFormData({
        ...formData,
        ...userSelect,
      });
    }
  }, [userSelect]);

  // ###################---SATISH KUMAR CHANGES----##################----END----############################

  return (
    <div>
      <div className="">
        <div className="form mt-4 mb-0">
          <Button
            onClick={() => {
              setNewUserOption(!newUserOption);
              setFormData(initialValue);
              setUserSelect();
            }}
            variant="contained"
            size="small"
            className="mb-2"
          >
            {newUserOption ? "New User" : "Already registered"}
          </Button>
          <div className="inputesC1 mb-2 d-flex flex-wrap">
            {/* ###################---SATISH KUMAR CHANGES----##################----START----############################ */}
            {newUserOption ? (
              <Autocomplete
                // fullWidth

                id="free-solo-demo"
                sx={{ width: "250px" }}
                size="small"
                options={partyList}
                className="m-1 align-self-center"
                value={userSelect}
                getOptionLabel={(value) => `${value.name}`}
                noOptionsText="Name not found"
                onChange={(event, newValue) => setUserSelect(newValue)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    placeholder="Select a name"
                    sx={{ width: "100%" }}
                    {...params}
                    label="Name*"
                  />
                )}
              />
            ) : (
              <TextField
                id="outlined-basic"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
                required="true"
                size="small"
                value={formData.name}
                label="Name"
                className="m-1 align-self-center"
                name="name"
                type="text"
                variant="outlined"
              />
            )}

            {/* ###################---SATISH KUMAR CHANGES----##################----END----############################ */}

            <TextField
              id="outlined-basic"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              required="true"
              size="small"
              label="Address"
              value={formData.address}
              className="m-1 align-self-center"
              name="address"
              type="text"
              variant="outlined"
              style={{ width: "200px" }}
            />

            <TextField
              id="outlined-basic"
              size="small"
              label="number"
              type="tel"
              className="m-1 align-self-center"
              name="mobileNo"
              variant="outlined"
              value={formData.mobileNo}
              required="true"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              style={{ width: "200px" }}
            />
            <TextField
              id="outlined-basic"
              format="MM/DD/YYY"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              size="small"
              value={formData.date}
              style={{ width: "200px" }}
              type="date"
              // options={products != null ? products : []}
              views="                     "
              className="m-1 align-self-center"
              name="date"
              variant="outlined"
            />

            <ButtonGroup className="d-block align-self-center">
              {PaymentMode.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`paymentmode-${idx}`}
                  type="radio"
                  variant={idx % 2 ? "outline-success" : "outline-success"}
                  name="paymentmode"
                  required="true"
                  value={radio.value}
                  checked={priceMode === radio.value}
                  onChange={(e) => {
                    setpriceMode(e.currentTarget.value);
                    setpriceModeName(radio.name);
                  }}
                  className="ToggleClassBtnRadio"
                >
                  {radio.name}
                  {/* {setpriceModeName(radio.name)} */}
                </ToggleButton>
              ))}
            </ButtonGroup>

            <TextField
              id="outlined-basic"
              size="small"
              label="Advance"
              value={formData.advance}
              type="number"
              className="m-1 align-self-center"
              name="advance"
              variant="outlined"
              required="true"
              style={{ width: "200px" }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
            />

            <TextField
              id="outlined-basic"
              size="small"
              label="GST Number"
              type="text"
              value={formData.gstNumber}
              className="m-1 align-self-center"
              name="gstNumber"
              variant="outlined"
              required="true"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              style={{ width: "200px" }}
            />
          </div>
          <hr />
          <div>
            <button className="btn btn-sm btn-danger px-5 pt-2">
              <h6>{["Mediator", "Retailer"][currentType - 1]}</h6>
            </button>
          </div>
          <hr />

          <div className="  inputes2 mt-2 mb-4 ">
            <Autocomplete
              id="free-solo-demo"
              className="inputFieldp  mx-2"
              // sx={{ width: 300 }}
              options={products != null ? products : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="party name"
                  label="Product"
                  variant="outlined"
                  size="small"
                />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  // console.log({ id: value.id });
                  setUserSelectProductId(value.id);
                  setCurrentProduct(value.label);
                  updateCurrentPrice("product", value.price);
                }
              }}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Unit}
              className="inputField  mx-2"
              size="small"
              // style={{ width: "150px" }}
              renderInput={(params) => <TextField {...params} label="Unit" />}
              defaultValue="Sq.ft."
              onChange={(event, value) => {
                if (value != null) {
                  updateCurrentPrice("unit", value.label);
                }
              }}
            />

            <TextField
              id="unitPrice"
              label="Unit Price / sq.ft."
              variant="outlined"
              size="small"
              className="inputField  px-2"
              // style={{ width: "130px" }}
              value={currentUnitPrice}
            />
            <TextField
              id="outlined-basic"
              // value={34}
              size="small"
              label="Width"
              className="inputField  px-2"
              name="width"
              type="number"
              variant="outlined"
              // style={{ width: "100px" }}
              value={currentWidth}
              onChange={(e) => {
                updateCurrentPrice("width", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              // value={34}
              size="small"
              label="Height"
              className="inputField  px-2"
              name="height"
              type="number"
              variant="outlined"
              // style={{ width: "100px" }}
              value={currentHeight}
              onChange={(e) => {
                updateCurrentPrice("height", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              // value={34}
              size="small"
              label="Quantity"
              className="inputField  px-2"
              name="quantity"
              type="number"
              variant="outlined"
              // style={{ width: "100px" }}
              value={currentQuantity}
              onChange={(e) => {
                updateCurrentPrice("quantity", e.target.value);
              }}
            />

            <TextField
              id="sqft"
              size="small"
              label="Sq.ft."
              className="inputField  px-2"
              name="sqft"
              variant="outlined"
              // style={{ width: "150px" }}
              value={currentSqft}
              readOnly
            />

            <TextField
              id="price"
              size="small"
              label="Price"
              className="inputField  px-2"
              name="Price"
              type="number"
              variant="outlined"
              // style={{ width: "150px" }}
              value={currentPrice}
              onChange={(e) => {
                setCurrentPrice(e.target.value);
              }}
            />

            {/* <Button
                            variant="contained"
                            className="AddButton"
                            onClick={listOfItems}
                        >
                            Add
                        </Button> */}
            <Button
              variant="contained"
              className="AddButtons"
              onClick={listOfItems}
              disabled={
                !formData.address ||
                !formData.name ||
                !formData.mobileNo ||
                !priceModeName
              }
            >
              Add
            </Button>
          </div>
        </div>

        {/* Overview */}

        <div className="ReceptContainer" ref={(el) => setPrintComponentRef(el)}>
          <div className="text-center">
            <div className="header  ReceptBg ">
              <div class="row logoContainer ">
                <div class="col-sm-12  col-md-6 text-start">
                  <img src={logo} alt="logo" className="img-fluid" />
                </div>

                <div class="col-sm-12  col-md-6">
                  <p className="py-3 pl-4 text-start">
                    Shop No.2, vithoba Complex, Sangli-Miraj Road <br />{" "}
                    Nr.Parshwanthnagar Bus Stop, Miraj - 416 410 (Maha) <br />{" "}
                    Mobile: 7293242424, e-mail: imprint24.india@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <h5 className="bg-secondary text-white px-2">TAX INVOICE</h5>
            <div className="p-2 text-start">
              <div className="row">
                <div className="col-6">
                  <p className="text-start">
                    Party Name: &nbsp; {formData.name}{" "}
                  </p>
                </div>
                <div className="col-6">
                  <p> Phone No: &nbsp;{formData.mobileNo} </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Address: &nbsp; {formData.address} </p>
                </div>
                <div className="col-6">
                  <p>
                    Date : &nbsp;
                    <b>
                      {formData.date == null
                        ? dayjs(Date.now()).format("DD/MM/YYYY")
                        : dayjs(formData.date).format("DD/MM/YYYY")}
                    </b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    Bill Number :{" "}
                    <b>
                      {String(dayjs(Date.now()).format("MMMYY/DD")).toUpperCase()}
                      {"/"}
                      {billId}
                    </b>
                  </p>
                </div>
                <div className="col-6">
                  <p>
                    Payment Mode :&nbsp;
                    {priceModeName}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    GST Number :&nbsp;
                    {formData.gstNumber}
                  </p>
                </div>
              </div>
            </div>
            <h6 className="bg-secondary text-secondary px-2">.</h6>

            <table>
              <tr>
                <th className="text-start">Sr. No.</th>
                <th className="text-start">Product</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Rate / sq.ft.</th>
                <th className="text-center">W/H</th>
                <th className="text-center">Unit</th>
                <th className="text-center">Sq. ft.</th>
                <th className="text-center">Total</th>
                <th className="d-print-none text-center">Action</th>
              </tr>

              {items.map((item, id) => {
                return (
                  <tr key={id}>
                    <td className="text-start">{id + 1}</td>
                    <td className="text-start">{item.product}</td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center">
                      {(item.price / item.sqft).toFixed(2)}
                    </td>
                    <td className="text-center">
                      {item.width + " X " + item.height}
                    </td>
                    <td className="text-center">{item.unit}</td>
                    <td className="text-center">{item.sqft}</td>

                    <td className="text-center">
                      {Number(item.price).toFixed(2)}
                    </td>
                    <td className="d-print-none text-center">
                      <span onClick={() => deleteItem(id)}>
                        {" "}
                        <DeleteIcon color="error" />{" "}
                      </span>
                    </td>
                  </tr>
                );
              })}

              <br />
              <tr></tr>

              <tr>
                <td colSpan={7}>Total amount before tax</td>
                <td className="text-center">{netAmount}</td>
              </tr>
              <tr>
                <td colSpan={7}>SGST 9%</td>
                <td className="text-center">{(netAmount * 0.09).toFixed(2)}</td>
              </tr>
              <tr>
                <td colSpan={7}>CGST 9%</td>
                <td className="text-center">{(netAmount * 0.09).toFixed(2)}</td>
              </tr>

              <tr>
                <td colSpan={7}>
                  <b>Grand Total</b>
                </td>
                <td className="text-center">
                  <b>{(netAmount * 0.18 + netAmount).toFixed(2)}</b>
                </td>
              </tr>
            </table>
            <hr />
            <div className="row">
              <div className="col-4 col-md-4">
                <h6
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  GST No.: <b>27EMNPP2479H1ZD</b>
                </h6>
              </div>
              <div className="col-6 col-md-4">
                <img src="/Assets/QrCode1.jpg" height="100" width="100" />
              </div>
              <div className="col-4 col-md-4"></div>
            </div>
          </div>
        </div>

        {/* invoice */}

        <ReactToPrint content={() => printComponentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <Button
                onClick={handlePrint}
                className="mb-5 mt-0 mr-3"
                color="primary"
                variant="contained"
                style={{ float: "right" }}
                disabled={saveFlag == true ? false : true}
              >
                PRINT
              </Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
        {/* save Bill */}
        <Button
          className=" mr-3"
          color="secondary"
          variant="contained"
          style={{ float: "right", marginRight: "20px" }}
          // type="submit"
          onClick={(e) => {
            setSaveFlag(true);
            saveBill();
          }}
          disabled={!formData.address || !formData.name || !formData.mobileNo}
        >
          SAVE BILL
        </Button>
      </div>
    </div>
  );
};

export default GSTBill;
